import type { AppProps } from 'next/app'
import { IntlProvider } from 'react-intl'
import { GTM_ID } from '@utils/gtm'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import '@fontsource/inter/variable.css'
import '../styles/globals.css'
import '../styles/range.scss'

function MyApp({ Component, pageProps }: AppProps) {
  const { locale, defaultLocale, query } = useRouter()

  useEffect(() => {
    // get utm_source from url and add it to cookies for two weeks
    const utm_source = query.utm_source || ''
    if (utm_source) {
      document.cookie = `utm_source=${utm_source}; max-age=1209600`
    }
  }, [query.utm_source])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {/* Google Tag Manager - Global base code */}
      {GTM_ID && <Script
        id='google-tag-manager-script'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />}
      <IntlProvider
        locale={locale || 'ru'}
        defaultLocale={defaultLocale}
        messages={pageProps.intlMessages}
      >
        <Component {...pageProps} />
      </IntlProvider>
    </>
  )
}
export default MyApp
